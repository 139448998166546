import { createSelector, ParametricSelector } from 'reselect';
import type { Store, StoreState } from '@common/types/store';

export default (() => {
  let store: Store;

  return {
    setStore: (s: Store) => (store = s),
    createSelector <T>(
      selectors: ParametricSelector<StoreState, any, any>[],
      fn: (state: StoreState, ...params: any[]) => T,
    ): (_: any, ...params: any[]) => T {
      return createSelector(
        // @ts-expect-error
        selectors,
        (...params) => fn(store.getState(), ...params),
      );
    },
    // Selectors can only have one cache, so when creating a selector for multiple items
    // you need to create a separate selector for each of them
    createItemSelector <T>(
      selectors: ParametricSelector<StoreState, any, any>[],
      fn: (state: StoreState, ...params: any[]) => T,
    ): () => (_: any, ...params: any[]) => T {
      return () => createSelector(
        // @ts-expect-error
        selectors,
        (...params) => fn(store.getState(), ...params),
      );
    },
  };
})();
