export const ERoleTypes = <const>{
  EMPLOYEE: 'EMPLOYEE',
  ADMIN: 'ADMIN',
  ORGANISATION_ADMIN: 'ORGANISATION_ADMIN',
};

export const ESourceTypes = {
  MESSAGE: 'message',
  EXCHANGE: 'exchange',
  CONVERSATION: 'conversation',
  ONBOARDING: 'onboarding',
  SCHEDULE: 'schedule',
  COURSE: 'course',
  EVENT: 'event',
};

export const EExplanationVideos = {
  TIMELINE: 'timeline',
  FLEXCHANGE: 'flexchange',
  ONBOARDING: 'onboarding',
  ORGANISATION_MESSAGES: 'organisation-messages',
};

export const EComponentTypes = <const>{
  SOCIAL: 'SOCIAL',
  FLEXCHANGE: 'FLEXCHANGE',
  SCHEDULE: 'SCHEDULE',
  CHAT: 'CHAT',
  ONBOARDING: 'ONBOARDING',
  CHANNELS: 'CHANNELS',
  ACADEMY: 'ACADEMY',
  ANALYTICS: 'ANALYTICS',
  TRANSLATION: 'TRANSLATION',
  FEED_TRANSLATION: 'FEED_TRANSLATION',
};

export const EPrivacyLevels = {
  ORGANISATION: 2,
  NETWORK: 1,
  NOBODY: 0,
};

export const EFileTypes = {
  UNKNOWN: null,
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  DOCUMENT: 'document',
  SPREADSHEET: 'spreadsheet',
  PRESENTATION: 'presentation',
  EXTERNAL_VIDEO: 'external_video',
  PDF: 'pdf',
};

export const fileTypesStyles = {
  'null': '/static/images/files/unknown.png',
  [EFileTypes.IMAGE]: '/static/images/files/jpg.png',
  [EFileTypes.VIDEO]: '/static/images/files/mp4.png',
  [EFileTypes.AUDIO]: '/static/images/files/audio.png',
  [EFileTypes.DOCUMENT]: '/static/images/files/doc.png',
  [EFileTypes.SPREADSHEET]: '/static/images/files/xls.png',
  [EFileTypes.PRESENTATION]: '/static/images/files/presentation.png',
  [EFileTypes.EXTERNAL_VIDEO]: '/static/images/files/mp4.png',
  [EFileTypes.PDF]: '/static/images/files/pdf.png',
};

export const EMetricTypes = {
  PRIVATE_MESSAGES_CREATED: 'private_message.created',
  FEED_MESSAGES_CREATED: 'feed_message.created',
  HIGHFIVES_CREATED: 'highfive.created',
  COMMENTS_CREATED: 'comment.created',
  USERS_ACTIVE: 'users.active',
  USERS_INVITED: 'users.invited',
  USERS_REGISTERED: 'users.registered',
  USERS_PENDING: 'users.pending',
  EXCHANGES_CREATED: 'exchanges.created',
  EXCHANGES_APPROVED: 'exchanges.approved',
  EXCHANGES_TOTAL_APPROVED_TIME_IN_SECONDS: 'exchanges.total_approved_time_in_seconds',
};

export const ESocketEvents = {
  ACTIVITY_CREATED: 'activity:created',
  CONVERSATION_ACTIVITY_CREATED: 'conversation_activity:created',
  FEED_MESSAGE_CREATED: 'feed_message:created',
  PRIVATE_MESSAGE_CREATED: 'private_message:created',
  PRIVATE_MESSAGE_CREATED_SENDER: 'private_message:created:sender',
  PRIVATE_MESSAGE_REMOVED: 'private_message:removed',
  PRIVATE_MESSAGE_UPDATED: 'private_message:updated',
  USER_STATUS_UPDATED: 'user:status:updated',
};

export enum UserProfileTypes { OWN, ADMIN, FEED }
