import * as R from 'ramda';
import { combineReducers } from 'redux';

import Storage from '../../../common/services/storage';
import flexchange from '../../flexchange/reducers/ui';
import network from '../../network/reducers/ui';
import social from '../../social/reducers/ui';
import * as organisationUI from '../../organisation/reducers/ui';
import planPackageModal from './plan-package-modal';

const uiReducer = combineReducers({
  ...organisationUI,
  network,
  flexchange,
  social,
  planPackageModal,
});

const initialState = uiReducer(undefined, {});

export const mapLocalStorageToState = (localState) => {
  if (!localState) return initialState;

  return {
    ...initialState,
    social: { ...initialState.social, ...localState.social },
  };
};

export const mapStateToLocalStorage = (state) => ({
  social: R.pick(['order'], state.social),
});

export default (state, action) => {
  const newState = uiReducer(state, action);

  if (action.sync) Storage.setObject('localState', mapStateToLocalStorage(newState));

  return newState;
};
