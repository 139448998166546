import { ThunkAction } from 'redux-thunk';
import * as R from 'ramda';

import Api from '../../../common/services/api';

import type { StoreState } from '@common/types/store';
import type {
  FunctionLink,
  Network,
  NetworkScope,
  Role,
} from '@common/types/objects';

export const CORE_ACCEPT_DOCUMENT = 'core/ACCEPT_DOCUMENT';
export const CLEAR_NOTIFICATION = 'core/CLEAR_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'core/CLEAR_NOTIFICATIONS';
export const CLEAR_UNREAD = 'core/CLEAR_UNREAD';
export const RECEIVE_FILE = 'core/RECEIVE_FILE';
export const NETWORK_RECEIVE_USER_FUNCTIONS = 'core/RECEIVE_USER_FUNCTIONS';
export const RECEIVE_NOTIFICATIONS = 'core/RECEIVE_NOTIFICATIONS';
export const RECEIVE_PROFILE = 'core/RECEIVE_PROFILE';
export const CORE_RECEIVE_USER = 'core/RECEIVE_USER';
export const CORE_INITIALISE = 'core/INITIALISE';
export const CORE_INTEGRATION_AUTHENTICATED = 'core/INTEGRATION_AUTHENTICATED';
export const RECEIVE_NOTIFICATION = 'core/RECEIVE_NOTIFICATION';
export const UPDATE_PROFILE = 'core/UPDATE_PROFILE';
export const CORE_RECEIVE_LANGUAGES = 'core/RECEIVE_LANGUAGES';
export const CORE_REFRESH_REDEEM_CODE = 'core/REFRESH_REDEEM_CODE';
export const CORE_DISMISS = 'core/DISMISS';
export const SET_USER_STATUS = 'core/SET_USER_STATUS';
export const UNSET_USER_STATUS = 'core/UNSET_USER_STATUS';
export const SHOW_PLAN_PACKAGE_UPGRADE_MODAL = 'core/SHOW_PLAN_PACKAGE_UPGRADE_MODAL';
export const HIDE_PLAN_PACKAGE_UPGRADE_MODAL = 'core/HIDE_PLAN_PACKAGE_UPGRADE_MODAL';

export type SetUserStatusAction = {
  type: typeof SET_USER_STATUS,
  emoji: string,
  text: string,
  expires_at: string,
  userId: string
};

// @ts-expect-error
export const refreshRedeemCode = (userId: string) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: code } = await Api.post(`/v1/organisations/${selected.id}/users/${userId}/redeem-code/request`);

  dispatch({
    type: CORE_REFRESH_REDEEM_CODE,
    userId,
    code,
  });
};

export type AddUserToCommunityAction = {
  type: 'core/ADD_USER_TO_COMMUNITY';
  userId: string;
  networks: NetworkScope[];
};

type ActualAddUserToCommunityAction = ThunkAction<
Promise<AddUserToCommunityAction>,
StoreState,
unknown,
AddUserToCommunityAction
>;

export const addUserToCommunity = (
  userId: string,
  network: Network,
  roles?: Role[],
): ActualAddUserToCommunityAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.put(`/v2/organisations/${selected.id}/users/${userId}/networks`, { add: [network.id] });

  if (roles && !R.isEmpty(roles)) {
    await Api.put(`/v1/networks/${network.id}/users/${userId}/roles`, { add: roles.map(({ id }) => id) });
  }

  type GetApiResponse = { data: { scopes: { networks: NetworkScope[] } } };
  const { data } = await Api.get<GetApiResponse>(`/v2/organisations/${selected.id}/users/${userId}`);

  return dispatch({
    type: 'core/ADD_USER_TO_COMMUNITY',
    userId,
    networks: data.scopes.networks,
  });
};

export type RemoveUserFromCommunity = {
  type: 'core/REMOVE_USER_FROM_COMMUNITY';
  userId: string;
  networkId: string;
};

type ActualRemoveUserFromCommunity = ThunkAction<
Promise<RemoveUserFromCommunity>,
StoreState,
unknown,
RemoveUserFromCommunity>;

export const removeUserFromCommunity = (
  userId: string,
  networkId: string,
  hasOrganisationRemovePermission: boolean,
): ActualRemoveUserFromCommunity => async (dispatch, getState) => {
  if (hasOrganisationRemovePermission) {
    const { organisation: { selected } } = getState();
    await Api.put(`/v2/organisations/${selected.id}/users/${userId}/networks`, { remove: [networkId] });
  } else {
    await Api.delete(`/v1/networks/${networkId}/users/${userId}`);
  }

  return dispatch({
    type: 'core/REMOVE_USER_FROM_COMMUNITY',
    userId,
    networkId,
  });
};

export type AddUserToFunctionGroup = {
  type: 'core/ADD_USER_TO_FUNCTION_GROUP';
  functionGroup: FunctionLink;
  userId: string;
};

type ActualAddUserToFunctionGroup = ThunkAction<
Promise<AddUserToFunctionGroup>,
StoreState,
unknown,
AddUserToFunctionGroup
>;

export const addUserToFunctionGroup = (
  userId: string,
  functionGroup: FunctionLink,
  networkId: string | undefined,
): ActualAddUserToFunctionGroup => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const endpoint = networkId
    ? `/v2/networks/${networkId}/users/${userId}/functions`
    : `/v2/organisations/${selected.id}/users/${userId}/functions`;

  await Api.put(endpoint, { add: [functionGroup.id] });

  return dispatch({
    type: 'core/ADD_USER_TO_FUNCTION_GROUP',
    userId,
    functionGroup,
  });
};

export type RemoveUserFromFunctionGroup = {
  type: 'core/REMOVE_USER_FROM_FUNCTION_GROUP';
  functionGroupId: string;
  userId: string;
};

type ActualRemoveUserFromFunctionGroup = ThunkAction<
Promise<RemoveUserFromFunctionGroup>,
StoreState,
unknown,
RemoveUserFromFunctionGroup
>;

export const removeUserFromFunctionGroup = (
  userId: string,
  functionGroupId: string,
  networkId: string | undefined,
): ActualRemoveUserFromFunctionGroup => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const endpoint = networkId
    ? `/v2/networks/${networkId}/users/${userId}/functions`
    : `/v2/organisations/${selected.id}/users/${userId}/functions`;

  await Api.put(endpoint, { remove: [functionGroupId] });

  return dispatch({
    type: 'core/REMOVE_USER_FROM_FUNCTION_GROUP',
    functionGroupId,
    userId,
  });
};
