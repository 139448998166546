import { useEffect, useRef, useState, useCallback } from 'react';
import { Api } from './api';
import { toQuery } from './utils';

interface UseRequestConfig<T> {
  useCursor?: boolean;
  useOffset?: boolean;
  limit?: number;
  onSuccess?: (data: T) => void;
  onError?: (error: Error) => void;
  payload?: Record<string, any>;
  transform?: (result: T) => T;
}

export function useRequest<T extends { data: any[], meta: any }>(
  endpoint: string,
  config: UseRequestConfig<T> = {}
) {
  const {
    onSuccess,
    onError,
    limit,
    payload = {},
  } = config;
  const [data, setData] = useState<T['data']>([]);
  const [meta, setMeta] = useState<T['meta']>({});
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const offset = useRef<number | null>(null);
  const cursor = useRef<string | boolean>(true);
  const abortControllerRef = useRef<AbortController | null>(null);
  const hasFetchedRef = useRef(false);

  const fetchData = useCallback(async (reset = false) => {
    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();

    setLoading(true);
    setError(null);

    try {
      const params = {
        ...(config.useOffset ? { offset: offset.current !== null ? offset.current + (limit || 0) : 0 } : {}),
        ...(config.useCursor ? { cursor: cursor.current } : {}),
        ...(config.limit ? { limit } : {}),
        ...payload,
      };

      const query = toQuery(params);
      const rawResult = await Api.get<T>(`${endpoint}?${query}`, true, {
        signal: abortControllerRef.current?.signal
      });

      const result = config.transform ? config.transform(rawResult) : rawResult;

      switch (true) {
        case config.useOffset:
          setData((prev) => (reset ? result.data : [...prev, ...result.data]));
          setMeta(result.meta);
          if (reset) {
            offset.current = result.meta.pagination?.offset || 0;
          } else {
            offset.current = null;
          }
          break;
        case config.useCursor:
          setData((prev) => (reset ? result.data : [...prev, ...result.data]));
          setMeta(result.meta);
          cursor.current = result.meta.pagination?.next_cursor || true;
          break;
        default:
          setData(result.data);
          break;
      }
      setLoading(false);
      onSuccess?.(result);
    } catch (err: any) {
      if (err.name === 'AbortError') {
        return;
      }
      const newErr = err instanceof Error ? err : new Error(String(err));
      setError(newErr);
      onError?.(newErr);
      setLoading(false);
    }
  }, [endpoint, limit, payload, onSuccess, onError]);

  useEffect(() => {
    if (!hasFetchedRef.current) {
      fetchData();
      hasFetchedRef.current = true;
    }
  }, [fetchData]);

  const reset = useCallback(() => {
    setData([]);
    cursor.current = true;
    offset.current = null;
    fetchData(true);
  }, [fetchData]);

  return {
    data,
    setData,
    meta,
    error,
    loading,
    refetch: () => fetchData(false),
    reset
  };
}
