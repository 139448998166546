import { ESocketEvents } from '@modules/core/definitions';
import SocketService from '../../../common/services/socket';
import Api from '../../../common/services/api';
import NotificationService from '../../../common/services/notification';
import initialiseOrganisation from '../../organisation/actions/initialise';
import selectOrganisation from '../../organisation/actions/select-organisation';
import initialiseNetwork from '../../network/actions/initialise';
import selectNetwork from '../../network/actions/select-network';
import logout from '../../authentication/actions/logout';
import receiveTimelineMessage from '../../social/actions/receive-message';
import receiveNotification from './receive-notification';
import { ESourceTypes } from '../../../common/definitions';
import { CHAT_UPDATE_NOTIFICATIONS } from '../../../common/hooks/use-update-notifications';

export const CORE_INITIALISE = 'core/INITIALISE';

export const NOTIFICATION_TYPE_WHITELIST = [
  ESourceTypes.CONVERSATION,
  ESourceTypes.MESSAGE,
  ESourceTypes.EXCHANGE,
  ESourceTypes.EVENT,
  ESourceTypes.ACCESS_REQUEST,
];

export default (history) => async (dispatch, getState) => {
  const { organisation, network, loggedUser: { user: { id: loggedUserId } } } = getState();

  try {
    // Load global values needed in the dashboard
    const coreData = [
      Api.get('/v1/available-languages'),
      Api.get('/v1/available-translations'),
      Api.get('/v1/phone-numbers'),
    ];

    let redirect;

    // Don't initialise the currently selected organisation when a network is selected
    // The initialise network action will initialise the parent organisation
    // This fixes an issue when users change the network id directly in the URL
    // Also not neccesary when server side rendering
    if (organisation.selected && !network.selected) {
      redirect = await dispatch(initialiseOrganisation(organisation.selected.id));
    }

    if (network.selected !== network.initialised) {
      redirect = await dispatch(initialiseNetwork(network.selected));
    }

    if (typeof redirect === 'string') return history.replace(redirect);

    // Initialise socket connection
    SocketService.initialise()
      .then(() => {
        SocketService.registerEvent(
          'me', ESocketEvents.ACTIVITY_CREATED, (...args) => dispatch(receiveNotification(...args))
        );
        SocketService.registerEvent(
          'me', ESocketEvents.FEED_MESSAGE_CREATED, (...args) => dispatch(receiveTimelineMessage(...args))
        );
        SocketService.registerEvent(
          'me', ESocketEvents.PRIVATE_MESSAGE_CREATED, async () => {
            const { data } = await Api.get(`/v1/organisations/${organisation.selected.id}/notifications/badge`);
            dispatch({
              type: CHAT_UPDATE_NOTIFICATIONS,
              notificationsCount: data.unread_conversations_count,
            });
          }
        );
      });

    // Ask permission for push notifications and update user id
    NotificationService.initialise(loggedUserId);

    // Listen for opened push notifications
    NotificationService.addListenerForOpened(async ({ data }) => {
      const currentState = getState();

      // If not one of these types of notification
      if (!NOTIFICATION_TYPE_WHITELIST.includes(data.type)) return;

      if (data.network_id) {
        // This also selects the correct organisation
        await dispatch(selectNetwork(data.network_id, false));
      } else if (data.organisation_id) {
        await dispatch(selectOrganisation(data.organisation_id, false));
      }

      switch (data.type) {
        case ESourceTypes.CONVERSATION:
          return history.push(`/conversations/${data.id}`);
        case ESourceTypes.MESSAGE:
          return history.push(`/messages/${data.id}`);
        case ESourceTypes.EXCHANGE:
          return history.push(`/networks/${data.network_id}/flexchange/exchanges/${data.id}`);
        case ESourceTypes.EVENT:
          if (currentState.network.selected) {
            return history.push(`/networks/${currentState.network.selected}/events/${data.id}`);
          }

          return;
        case ESourceTypes.ACCESS_REQUEST:
          if (data.network_id) {
            return history.push(`/networks/${data.network_id}/users/filter/requests`);
          }

          return history.push('/admin/users/filter/requests');
        default:
          return false;
      }
    });

    const [
      { data: languages },
      { data: translations },
      { data: dialCodes },
    ] = await Promise.all(coreData);

    return dispatch({
      type: CORE_INITIALISE,
      languages,
      translations,
      dialCodes,
    });
  } catch (err) {
    if (err.type === 'whitelabel_access_error') return history.push('/auth/organisations');
    if (err.type === 'auth_error') return dispatch(logout('error=profile'));

    throw err;
  }
};
