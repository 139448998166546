import * as R from 'ramda';
import Api from '@services/api';
import NotificationService from '@services/notification';
import { setTheme } from '@common/utils/color';
import acceptDocument from '@modules/core/actions/accept-document';
import { EPermissions } from '@common/definitions';

import * as i18n from '../../../i18n';

export const ORGANISATION_INITIALISE = 'organisation/INITIALISE';

export default (id) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  // $FlowFixMe
  const organisationId = id || selected?.id;

  if (!organisationId) return false;

  try {
    const [
      { data: organisation },
      { data: user },
      { data: networks },
      { data: admins },
      { data: channels },
      { data: functions },
      { data: badge },
    ] = await Promise.all([
      Api.get(`/v2/organisations/${organisationId}`),
      Api.get(`/v4/organisations/${organisationId}/users/me`),
      Api.get(`/v4/organisations/${organisationId}/networks`),
      Api.get(`/v3/organisations/${organisationId}/admins`),
      Api.get(`/v2/organisations/${organisationId}/channels`),
      Api.get(`/v3/organisations/${organisationId}/functions`)
        .catch(() => ({ data: [] })),
      Api.get(`/v1/organisations/${organisationId}/notifications/badge`),
    ]);

    // ONE-397 : Check if user has permission to billing before making api call to avoid 403
    let billing = { data: null };
    if (user.permissions.includes(EPermissions.GLOBAL_ORGANISATION_ADMINS_ADD)) {
      billing = await Api.get(`/v2/organisations/${organisationId}/billing`)
        .catch(() => ({ data: null }));
    }

    const browserTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
    let userTimeZoneToSwitch = null;
    if (
      (
        !user.time_zone || user.time_zone !== browserTimeZone
      ) && typeof browserTimeZone === 'string'
    ) {
      userTimeZoneToSwitch = browserTimeZone;
      Api.put(`/v4/organisations/${organisationId}/users/me`, {
        time_zone: browserTimeZone
      });
    }

    // By logging in (for the first time) you by default accept our privacy terms
    if (!user.last_login && !user.accepted_privacy) dispatch(acceptDocument('privacy'));
    // Set language for user
    i18n.changeLanguage(user.language.locale);
    // Enable notifications
    NotificationService.subscribe(user.id);
    // Update theme to be organisation specific
    setTheme(organisation.theme);

    let setupProgress = null;

    try {
      if (organisation.trial) {
        setupProgress = R.omit(
          ['type', 'organisation_id', 'created_at', 'updated_at'],
          (await Api.get(`/v2/organisations/${organisationId}/setup_steps`)).data
        );
      }
    } catch (err) {
      console.log('error', err);
      // Do nothing
    }

    return dispatch({
      type: ORGANISATION_INITIALISE,
      organisation,
      user,
      userTimeZoneToSwitch,
      networks,
      admins,
      functions,
      channels,
      setupProgress,
      badge,
      billing,
      sync: true,
    });
  } catch (err) {
    console.error(err);
    // Something went wrong while initialising, redirect back to select screen
    return '/auth/organisations';
  }
};
