import * as React from 'react';
import * as R from 'ramda';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { Button } from '../../../../common/components/button';
import {
  Row,
  Group,
  TextInput,
  CheckboxInput,
  PasswordInput,
} from '../../../../common/components/form';
import Alert from '../../../../common/components/alert';
import { authenticate, fetchOrganisations } from '../../actions';
import ResetPasswordForm from '../reset-password';
import { selectOrganisation } from '../../../organisation/actions';

class LoginForm extends React.Component {
  static defaultProps = {
    formValues: {},
  };

  constructor() {
    super();

    this.handleResetPasswordSuccess = this.handleResetPasswordSuccess.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      shouldSetPassword: false,
      authentication: null,
      formAlert: null,
    };
  }

  UNSAFE_componentWillMount() {
    const { username } = this.props;
    if (username) this.props.initialize({ username });
  }

  static props;

  async handleSubmit(credentials, dispatch) {
    const { history, t } = this.props;

    try {
      const { lastLogin, temporaryPassword } = await dispatch(authenticate(credentials));

      if (temporaryPassword) return this.setState({ shouldSetPassword: true, formAlert: null });
      if (!lastLogin) return history.push('/auth/registration');

      return this.handleRedirect();
    } catch (error) {
      if (error.type === 'wrong_credentials') {
        return this.setState({
          formAlert: {
            type: 'warning',
            message: t('authentication:error_incorrect_credentials'),
            error_info: (error.error_info && error.error_info.attempts_remaining <= 3 && t('authentication:error_remaining_attemps', { count: error.error_info.attempts_remaining })),
          },
        });
      } if (error.type === 'too_many_requests') {
        return this.setState({
          formAlert: {
            type: 'warning',
            message: t('authentication:error_incorrect_credentials'),
            error_info: error.error_info && t('authentication:error_remaining_minutes', { count: error.error_info.remaining_minutes }),
          },
        });
      }

      return this.setState({
        formAlert: {
          type: 'error',
          message: error.detail,
        },
      });
    }
  }

  async handleRedirect() {
    const { dispatch, history } = this.props;

    const whitelabelOrganisation = window.organisation;

    // If user is in whitelabel environment, then should instantly select that organisation
    if (whitelabelOrganisation) {
      return history.push(await dispatch(selectOrganisation(whitelabelOrganisation.id)));
    }

    const { data: organisations } = await dispatch(fetchOrganisations());

    const newOrganisation = R.findLast((org) => {
      // console.log("debug org", org);
      return org?.trial && !org.trial.setup_completed;
    }, organisations);

    // If you're in an organisation that needs to be setup
    if (newOrganisation) {
      return history.push('/auth/registration?org=' + newOrganisation.id);
    }

    // If you're only in a single organisation select that one
    if (organisations.length === 1) {
      return history.push(await dispatch(selectOrganisation(organisations[0].id)));
    }

    // If none of the above let user pick from a list of organisations
    return history.push('/auth/organisations');
  }

  handleResetPasswordSuccess() {
    this.setState({
      // $FlowFixMe
      authentication: {
        ...this.state.authentication,
        temporaryPassword: false,
      },
    }, this.handleRedirect);
  }

  render() {
    const { shouldSetPassword, formAlert } = this.state;
    const {
      history, hideUsername, labels, alert, submitting, handleSubmit, formValues, t,
    } = this.props;

    if (shouldSetPassword) return <ResetPasswordForm history={history} onSuccess={this.handleResetPasswordSuccess} />;

    return (
      <>
        <h2>{(labels && labels.title) || <Trans i18nKey="authentication:signup" />}</h2>
        {labels && labels.description}
        <form className="Form" onSubmit={handleSubmit(this.handleSubmit)}>
          {(formAlert && <Alert key={formAlert.error_info + formAlert.message} type={formAlert.type} title={formAlert.message}>{formAlert.error_info}</Alert>) || alert}

          {!(hideUsername && formValues.username) && (
            <Row>
              <Group>
                <TextInput
                  mask
                  name="username"
                  placeholder={t('authentication:label_email')}
                  type="text"
                  autoComplete="username"
                  onChange={(e) => {
                    this.props.change('username', e.target.value.trim());
                  }}
                />
              </Group>
            </Row>
          )}
          <Row>
            <Group>
              <PasswordInput
                name="password"
                placeholder={t(hideUsername ? 'authentication:label_temporary_password' : 'authentication:label_password')}
                type="password"
                autoComplete="current-password"
                onChange={(e) => {
                  this.props.change('password', e.target.value.trim());
                }}
              />
            </Group>
          </Row>
          <Row>
            <Button
              type="primary"
              size="fill"
              isLoading={submitting}
              disabled={!formValues.username || !formValues.password}
              buttonType="submit"
              icon="create"
              className="tw-mt-4"
            >
              <Trans i18nKey={hideUsername ? 'authentication:next' : 'authentication:login'} />
            </Button>
          </Row>
          {!hideUsername && (
            <div className="Authentication__Form__Action">
              <CheckboxInput
                name="remember_me"
                label={t('authentication:keep_logged_in')}
                checkedValue
                uncheckedValue={false}
              />
              <div className="pull-right">
                <Link to={`/auth/forgot${formValues.username ? `?username=${formValues.username}` : ''}`}>
                  <Trans i18nKey="authentication:forgot_password" />
                </Link>
              </div>
            </div>
          )}
        </form>
        {labels && labels.note}
      </>
    );
  }
}

export default withRouter(reduxForm({
  form: 'authenticate',
  initialValues: {
    username: '',
    password: '',
    remember_me: false,
  },
})(withTranslation()(LoginForm)));
