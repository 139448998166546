import * as R from 'ramda';
import Api from '@services/api';
import { ELevels, ECompanyDashboardPermissions, EPermissions } from '@common/definitions';

import selectNetwork from '../../network/actions/select-network';
import selectOrganisationCore from './select-organisation';

export const ORGANISATION_CREATE_CHANNEL = 'organisation/CREATE_CHANNEL';
export const ORGANISATION_DELETE_CHANNEL = 'organisation/DELETE_CHANNEL';
export const ORGANISATION_RECEIVE_CHANNELS = 'organisation/RECEIVE_CHANNELS';
export const ORGANISATION_RECEIVE_MESSAGE = 'organisation/RECEIVE_MESSAGE';
export const ORGANISATION_RECEIVE_MESSAGES = 'organisation/RECEIVE_MESSAGES';
export const ORGANISATION_UPDATE_CHANNEL = 'organisation/UPDATE_CHANNEL';
export const ORGANISATION_ADD_FUNCTION = 'organisation/ADD_FUNCTION';
export const ORGANISATION_ADD_FUNCTIONS = 'organisation/ADD_FUNCTIONS';
export const ORGANISATION_UPDATE_FUNCTION_USERS = 'organisation/UPDATE_FUNCTION_USERS';
export const ORGANISATION_ADD_NETWORKS = 'organisation/ADD_NETWORKS';
export const ORGANISATION_DELETE_FUNCTION = 'organisation/DELETE_FUNCTION';
export const ORGANISATION_DELETE_USER = 'organisation/DELETE_USER';
export const ORGANISATION_FILTER_USERS = 'organisation/FILTER_USERS';
export const ORGANISATION_INITIALISE = 'organisation/INITIALISE';
export const ORGANISATION_INVITE_USER = 'organisation/INVITE_USER';
export const ORGANISATION_RECEIVE_USERS = 'organisation/RECEIVE_USERS';
export const ORGANISATION_RESEND_INVITATION = 'organisation/RESEND_INVITATION';
export const SELECT_ORGANISATION = 'auth/SELECT_ORGANISATION';
export const ORGANISATION_EDIT_FUNCTION = 'organisation/EDIT_FUNCTION';
export const ORGANISATION_STOP_EDIT_FUNCTION = 'organisation/STOP_EDIT_FUNCTION';
export const ORGANISATION_UPDATE_FUNCTION = 'organisation/UPDATE_FUNCTION';
export const ORGANISATION_UPDATE_ORGANISATION = 'organisation/UPDATE_ORGANISATION';
export const ADMIN_UPDATE_ROLES = 'admin/UPDATE_ROLES';
export const ORGANISATION_UPDATE_USER_ROLE = 'organisation/UPDATE_USER_ROLE';
export const ORGANISATION_UPDATE_USER = 'employees/UPDATE_USER';
export const ADMIN_RECEIVE_ROLES = 'admin/RECEIVE_ROLES';
export const ORGANISATION_RECEIVE_ADMINS = 'organisation/RECEIVE_ADMINS';

export const fetchUsers = (offset = 0, filter = {}, limit = 30) => async (dispatch, getState) => {
  const { organisation: { selected: { id: organisationId } }, network: { selected: networkId } } = getState();

  const query = Api.utils.toQuery({
    offset,
    limit,
    q: filter.query,
    select: filter.status,
    sort_by: filter.sortBy,
    exclude_network_ids: filter.excludeCurrentNetwork ? networkId : null,
    can_chat: filter.canChat,
    network_ids: filter.networkIds,
    function_ids: filter.functionIds,
  });

  const request = await Api.get(`/v2/organisations/${organisationId}/users?${query}`);

  const { data: items, meta: { pagination } } = request;

  dispatch({
    type: ORGANISATION_RECEIVE_USERS,
    items,
    pagination,
    strategy: pagination.offset > 0 ? 'append' : null,
  });

  return request;
};

export const fetchAdmins = () => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const response = await Api.get(`/v3/organisations/${selected.id}/admins`);

  dispatch({
    type: ORGANISATION_RECEIVE_ADMINS,
    items: response.data,
  });

  return response;
};

export const fetchNetworks = (organisationId) => async () => {
  const response = await Api.get(`/v4/organisations/${organisationId}/networks`);

  return response;
};

let promise;

export const fetchRoles = () => async (dispatch, getState) => {
  if (promise) return promise;

  const { organisation: { selected } } = getState();

  promise = Api.get(`/v1/organisations/${selected.id}/roles`);

  const request = await promise;

  dispatch({
    type: ADMIN_RECEIVE_ROLES,
    items: request.data,
  });

  promise = null;

  return request;
};

const cache = {
  1: {},
  2: {},
};

export const fetchPermissionGroups = (level = ELevels.ORGANISATION) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  if (cache[level][selected]) return cache[level][selected];

  const { data: items } = await Api.get(`/v1/organisations/${selected.id}/permissions/groups?level=${level}`);

  const data = R.pipe(
    R.groupBy(R.prop('name')),
    R.map((group) => {
      if (group.length === 1) return group[0];

      return R.assoc('options', group.map((option) => ({ id: option.id, sub_level: option.sub_level, permissions: option.permissions })), group[0]);
    }),
    R.values,
  )(items);

  cache[level][selected] = { data };

  return { data };
};

export class NetworksSelectError extends Error {}

export const selectOrganisation = (organisationId, redirect = true) => async (dispatch) => {
  const { user } = await dispatch(selectOrganisationCore(organisationId, redirect));

  if (redirect) {
    const isAdmin = ECompanyDashboardPermissions.some((permission) => user.permissions.includes(permission));
    const canViewAllNetworks =
      [EPermissions.ORGANISATION_NETWORKS_VIEW_ALL].some((permission) => user.permissions.includes(permission));

    if (isAdmin) return '/admin';

    let { data: networks } = await dispatch(fetchNetworks(organisationId));
    if (!networks.length) throw new NetworksSelectError();

    networks = networks.filter((item) => canViewAllNetworks || item.membership);

    const networkToSelect = networks.filter((network) => network.is_member)[0] || networks[0];

    return dispatch(selectNetwork(networkToSelect.id));
  }

  return '/admin';
};
