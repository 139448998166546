import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Api from '@common/services/api';

export const CHAT_UPDATE_NOTIFICATIONS = 'chat/CHAT_UPDATE_NOTIFICATIONS';

type GetOrganisationNotificationsResponse = {
  data: {
    pending_access_requests: number;
    unread_activities_count: number;
    unread_conversations_count: number;
    unseen_activities_count: number;
  };
};

export const useUpdateNotifications = () => {
  const dispatch = useDispatch();

  return useCallback(async (orgId: string) => {
    const { data } = await Api.get<GetOrganisationNotificationsResponse>(`/v1/organisations/${orgId}/notifications/badge`);
    dispatch({
      type: CHAT_UPDATE_NOTIFICATIONS,
      notificationsCount: data.unread_conversations_count,
    });
  }, [dispatch]);
};
