import { combineReducers } from 'redux';
import * as R from 'ramda';
import { SELECT_NETWORK } from '../../network/actions';
import { SELECT_ORGANISATION, ORGANISATION_INITIALISE } from '../../organisation/actions';
import { CHAT_UPDATE_NOTIFICATIONS } from '../../../common/hooks/use-update-notifications';
import { SOCIAL_RECEIVE_REALTIME_MESSAGE, SOCIAL_REQUEST_FEED, SOCIAL_POST_MESSAGE } from '../../social/actions';
import { RECEIVE_NOTIFICATIONS, CLEAR_NOTIFICATIONS, CLEAR_NOTIFICATION, RECEIVE_NOTIFICATION } from '../actions';
import { EParentTypes } from '../../../common/definitions';
import { ETimelineTypes } from '../../social/definitions';

const chat = (state = 0, action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      return action.badge.unread_conversations_count;
    case CHAT_UPDATE_NOTIFICATIONS:
      return action.notificationsCount;
    default: return state;
  }
};

const transformMessage = R.pick(['parent_id', 'parent_type']);
const byParent = (type, id) => R.both(R.propEq('parent_type', type), R.propEq('parent_id', id));
const removeByParent = ({ channel }, notifications = []) => {
  switch (channel.type) {
    case ETimelineTypes.FEED:
      return [];
    case ETimelineTypes.NETWORK:
      return R.reject(byParent(EParentTypes.NETWORK, channel.id), notifications);
    case ETimelineTypes.TEAM:
      return R.reject(byParent(EParentTypes.TEAM, channel.id), notifications);
    case ETimelineTypes.ORGANISATION:
      return R.reject(byParent(EParentTypes.ORGANISATION, channel.id), notifications);
    default: return notifications;
  }
};

const social = (state = { total: 0, created: [], items: [] }, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_REALTIME_MESSAGE:
      return {
        ...state,
        total: state.total + 1,
        items: [...state.items, action.item],
      };
    case SOCIAL_POST_MESSAGE:
      if (state.total === 0 || action.scheduled) return state;

      return {
        ...state,
        created: [...state.created, transformMessage(action.message)],
      };
    case SOCIAL_REQUEST_FEED: {
      const newItems = removeByParent(action, state.items);
      const newCreated = removeByParent(action, state.created);

      return {
        ...state,
        total: newItems.length,
        items: newItems,
        created: newCreated,
      };
    }
    case SELECT_NETWORK:
    case SELECT_ORGANISATION:
      return { total: 0, created: [], items: [] };
    default: return state;
  }
};

const notifications = (state = { total: 0 }, action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      try {
        return {
          ...state,
          total: action.badge.unseen_activities_count,
        };
      } catch (err) {
        return state;
      }
    case RECEIVE_NOTIFICATIONS:
      if (action.meta.pagination.offset === 0) return { total: action.meta.counts.unseen };

      return { total: state.total + action.meta.counts.unseen };
    case RECEIVE_NOTIFICATION:
      return { total: state.total + 1 };
    case CLEAR_NOTIFICATIONS:
      return { total: 0 };
    case CLEAR_NOTIFICATION:
      return { total: R.max(0, state.total - action.notificationsCount) };
    case SELECT_ORGANISATION: return { total: 0 };
    default: return state;
  }
};

export default combineReducers({ chat, social, notifications });
