import * as R from 'ramda';

import { getApiUrl, getApiHeaders, logApiError, toQuery } from './utils';

export type Request = Record<string, unknown>;

export type FailedRequest = {
  detail: string;
  error_code: string;
  error_info: string | null;
  status_code: number;
  type: string;
};

type Methods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

const disableLoggingRoutes = ['/v2/authenticate', '/v1/password/reset'];

async function callApi<T = Request>(
  method: Methods,
  endpoint: string,
  payload: unknown = {},
  requireAuth = true,
  preventInfinite = false,
  config?: any,
): Promise<T> {
  const headers = await getApiHeaders(payload, requireAuth, preventInfinite);

  const options: RequestInit = {
    method,
    headers,
    credentials: 'include',
    cache: 'default',
    ...(config || {}),
  };

  if (payload && R.contains(method, ['POST', 'PUT', 'PATCH', 'DELETE'])) {
    options.body = R.is(FormData, payload) ? payload : JSON.stringify(payload);
  }

  const response = await fetch(getApiUrl(endpoint), options);
  const body = response.json();

  if (!response.ok) {
    if (!disableLoggingRoutes.includes(endpoint)) {
      // Log errors
      logApiError(body, { method, endpoint, payload });
    }

    if (!preventInfinite && response.status === 401 && !endpoint.includes('integration_auth')) {
      return callApi(method, endpoint, payload, requireAuth, true);
    }

    throw await body;
  }

  return body;
}

export const Api = {
  get<T = Request>(endpoint: string, requireAuth = true, config?: any) {
    return callApi<T>('GET', endpoint, undefined, requireAuth, false, config);
  },
  post<T = Request>(endpoint: string, data?: Record<string, unknown> | FormData, requireAuth = true) {
    return callApi<T>('POST', endpoint, data, requireAuth);
  },
  put<T = Request>(endpoint: string, data?: Record<string, unknown>) {
    return callApi<T>('PUT', endpoint, data);
  },
  patch<T = Request>(endpoint: string, data?: Record<string, unknown>) {
    return callApi<T>('PATCH', endpoint, data);
  },
  delete<T = Request>(endpoint: string, data?: Record<string, unknown>) {
    return callApi<T>('DELETE', endpoint, data);
  },
  getHeaders(payload?: Record<string, unknown>) {
    return getApiHeaders(payload);
  },
  utils: {
    toQuery,
  },
};
