import React, { useState } from 'react';
import TextInputField from '../text/text';
import { BaseFieldProps } from 'redux-form';
import { TextInputOwnProps } from '../text/text-input';
import { Button } from '@common/components/button';

const PasswordInput = React.forwardRef<HTMLInputElement | undefined, BaseFieldProps & TextInputOwnProps>(
  (props, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div className="tw-relative password-input-wrapper">
        <TextInputField
          {...props}
          type={showPassword ? 'text' : 'password'}
          className="!tw-w-[calc(100%-32px-32px-2px)] !tw-pr-12 !tw-bg-[right_40px_center]"
          ref={ref}
        />
        <div
          className="tw-absolute tw-right-0 tw-top-0 tw-flex
          tw-items-center tw-justify-center tw-w-12 tw-h-[36px]"
        >
          <Button
            icon={showPassword ? 'visibility_off' : 'visibility'}
            onClick={togglePasswordVisibility}
            className="password-input-toggle-button !tw-bg-transparent"
          />
        </div>
      </div>
    );
  });

export default PasswordInput;
