import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector } from '@hooks/redux';
import { Permissions } from '@common/definitions';
import { ComponentTypesValues, hasEnabledComponent } from '@common/utils/has-enabled-component';
import * as organisationsSelector from '@modules/organisation/selectors/organisation';
import * as networkSelector from '@modules/network/selectors/network';
import * as userSelector from '../../modules/core/selectors/logged-user';
import { getNetworksWithPermission } from '@common/utils/permissions/get-networks-with-permission';
import { Network, NetworkScope } from '@common/types/objects';

const useComponentEnabledChecker = (
  requiredComponent: ComponentTypesValues | ComponentTypesValues[],
  some: boolean = true,
) => {
  const toCheck = useMemo(() => {
    return Array.isArray(requiredComponent) ? requiredComponent : [requiredComponent];
  }, [requiredComponent]);
  const org = useSelector(organisationsSelector.selected);
  const network = useSelector(networkSelector.selected);
  return toCheck[some ? 'some' : 'every']((component) => hasEnabledComponent(component, network, org));
};

const useNetworkPermissions = (
  permissions: Permissions | Permissions[],
  networks?: NetworkScope[] | Network[],
  some: boolean = true,
) => {
  const toCheck = useMemo(() => {
    return Array.isArray(permissions) ? permissions : [permissions];
  }, [permissions]);
  const loggedUserNetworks = useAppSelector(userSelector.networks);
  if (!networks) return false;
  return toCheck[some ? 'some' : 'every'](
    (permission) => {
      const userNetworks = getNetworksWithPermission(permission, loggedUserNetworks);
      for (const network of networks) {
        if (userNetworks.find((n) => n.id === network.id)) return true;
      }
      return false;
    }
  );
};

/**
 * @deprecated should only be used as an internal hook for useCanAccess,
 * use useCanAccess instead
 */
export const usePermission = (
  permissions: Permissions | Permissions[],
  some: boolean = true,
) => {
  const toCheck = useMemo(() => {
    return Array.isArray(permissions) ? permissions : [permissions];
  }, [permissions]);
  const userPermissions = useAppSelector(userSelector.permissions) as Permissions[];
  return useMemo(() => {
    return toCheck[some ? 'some' : 'every']((permission) => userPermissions.includes(permission));
  }, [userPermissions, toCheck]);
};

export type UseCanAccessType = {
  id?: string;
  permissions?: Permissions | Permissions[];
  components?: ComponentTypesValues | ComponentTypesValues[];
  componentsAnd?: ComponentTypesValues | ComponentTypesValues[];
  networkPermissions?: Permissions | Permissions[];
  networks?: NetworkScope[] | Network[];
  and?: boolean;
  or?: boolean;
};

export const useCanAccess = ({
  permissions,
  components,
  componentsAnd,
  networks,
  networkPermissions,
  and = true,
  or = false,
}: UseCanAccessType) => {
  const hasComponent = components?.length ? useComponentEnabledChecker(components) : true;
  const hasComponentAnd = componentsAnd?.length ? useComponentEnabledChecker(componentsAnd, false) : true;
  const hasPermission = permissions ? usePermission(permissions) : false;
  const hasNetworkPermission = networkPermissions?.length ? useNetworkPermissions(networkPermissions, networks) : false;
  return (
    hasPermission || hasNetworkPermission || (!permissions && !networkPermissions) || or
  ) && hasComponent && hasComponentAnd && and;
};

