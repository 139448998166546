import { Network } from '@common/types/objects';
import { Permissions } from '@common/definitions';

export const getNetworksWithPermission = (permission: Permissions, userNetworks: Network[]) => {
  let networks: Network[] = [];
  for (const network of userNetworks) {
    if (network.permissions.includes(permission)) {
      networks = [...networks, network];
    }
  }
  return networks;
};
